import React from 'react';
import './cardComponent.scss';

interface Props {
  image: string;
  heading: string;
  subHeading: string;
  subHeadingStrong: string;
  listHeading?: string;
  listItems: string[];
}

const CardComponent = ({
  image,
  heading,
  subHeading,
  subHeadingStrong,
  listHeading = 'Services Include',
  listItems,
}: Props) => {
  return (
    <div className='uk-width-1-1 uk-dark card-container'>
      <div className='uk-child-width-expand@m uk-flex-center'>
        <div className='uk-card uk-card-default uk-card-large uk-margin uk-flex uk-padding-large uk-flex flex-direction'>
          <div className='uk-flex-last uk-card-media-right uk-width-1-2@m center-image'>
            <img
              src={image}
              data-src={image}
              alt='Managing Kafka Brokers is enjoyable'
              data-uk-img
              className='image'
            />
          </div>
          <div className='uk-flex uk-flex-middle uk-width-expand@m uk-first-column'>
            <div className='uk-card-body'>
              <h3 className='uk-h1'>
                <mark className='mark'>{heading}</mark>
              </h3>
              <p className='sub-heading'>
                {subHeading} <strong>{subHeadingStrong}</strong>
              </p>
              <div className='list-heading'>
                <mark className='mark'>{listHeading}</mark>:
              </div>
              <ul className='list'>
                {listItems.map(value => {
                  return <li key={value}>{value}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
