import React from 'react';
import { Link } from 'gatsby';

import './comResFooter.scss';

const ComResFooter = () => {
  return (
    <div className='uk-section uk-section-large'>
      <div className='uk-container uk-container-xsmall uk-position-relative uk-text-center'>
        <div>
          <div className='uk-article-content'>
            <h1
              className='uk-margin-medium-bottom'
              id='still-have-licensing-questions'
            >
              In addition to one time services{' '}
              <mark className='mark'>Ample Maintenance</mark> can be booked for{' '}
              <strong>routine maintenance visits!</strong>
            </h1>
            <p>
              <Link
                className='uk-button uk-button-medium burly-button'
                to='/getaquote'
              >
                Get a Quote
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComResFooter;
